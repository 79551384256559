a {
  text-decoration: none !important;
  color: black !important;
}

h4 {
  font-size: 1rem;
}

.nft-image {
  max-height: 100px;
}

h2.ps-page-title {
  font-size: 3.125rem !important;
  line-height: 3.75rem !important;
  font-weight: 700;
  margin-bottom: 24px !important;
}

.header-top {
  background-color: #f2eeec;
}

.navbar-nav .nav-link {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

.logo {
  max-width: 115px;
}

.ps-footer-wrapper {
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

#footer h3.ps-footer-label {
  background: #e31f26;
  color: #fff;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 40px;
  position: absolute;
  left: 5%;
  top: -20px;
}

#footer h3.ps-footer-label:before {
  border-right: 18px solid #630E11;
  border-top: 20px solid transparent;
  content: "";
  display: block;
  height: 0;
  right: 100%;
  position: absolute;
  top: 0;
}

#footer {
  background-color: #F1F1F1;
}

.footer-bottom,
.footer-right {
  background-color: #C91C21;
  color: #fff;
}

.footer-bottom p {
  font-size: 0.9em;
}

.vh-80 {
  min-height: 80vh;
}

.nft {
  border-left: 4px solid #e31f26 !important;
}

.nft:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.08);
  border-left: 8px solid #e31f26;
  transform: translateX(10px);
  transition: all .15s ease-in-out;
}

.thumb-info-wrapper {
  margin: 0;
  overflow: hidden;
  display: block;
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

h4.entry-title {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: .25px;
}

#main {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
}